import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './components/home/Home';
import NoMatch from './components/NoMatch';
import Ortodoncja from './components/Ortodoncja';
import PrzedPo from './components/PrzedPo';

const Contact = React.lazy(() => import ("./components/contact/Contact"));
const Cennik = React.lazy(() => import ("./components/cennik/Cennik"));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
          <Route index path="/" element={<Home />} />
          <Route
            path="kontakt"
            element={
              <React.Suspense fallback={<>...</>}>
                <Contact />
              </React.Suspense>
            }
          />
          <Route exact
            path="cennik"
            element={
              <React.Suspense fallback={<>...</>}>
                <Cennik />
              </React.Suspense>
            }
          />
          <Route exact
            path="ortodoncja"
            element={
              <React.Suspense fallback={<>...</>}>
                <Ortodoncja />
              </React.Suspense>
            }
          />
          <Route exact
            path="/ortodoncja/przed-i-po"
            element={
              <React.Suspense fallback={<>...</>}>
                <PrzedPo />
              </React.Suspense>
            }
          />
          <Route path='*' exact={true} element={<NoMatch />} />
          </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
