import React from "react";

const PrzedPo = () => {
    return (
        <>
            <div className="section-wrapper" id="section-3a">
                <div className="container">
                    <section id="content4" class="row">
                        <div className="grc col-md-12 columns podstrona2a">
                            <div className="inner-wrapper">
                                <div class="content"><h2>PRZED I PO LECZENIU</h2>
                                    <p style="text-align: left;">Warunkiem rozpoczęcia leczenia jest zdrowie zębów i dziąseł. Przed założeniem aparatu stałego należy udać się do stomatologa na przegląd oraz wyczyścić zęby z osadów. Jeśli zajdzie taka potrzeba w naszym gabinecie możecie zostać Państwo objęci pełnym zakresem usług stomatologicznych.</p>
                                    <p style="text-align: left;">Przed montażem aparatu stałego zostaną Państwu pobrane wyciski (modele diagnostyczne) oraz zrobione zdjęcia wewnątrz- i zewnątrzustne.</p>
                                    <p style="text-align: left;">Należy dobrze zaopatrzyć lodówkę w miękkie pokarmy. Można również zaopatrzyć się w zestaw szczoteczek jednopęczkowych oraz opcjonalnie irygator, które będą pomocne w utrzymaniu prawidłowej higieny jamy ustnej w trakcie leczenia ortodontycznego.</p>
                                    <p style="text-align: left;">Po montażu aparatu udzielony zostanie Państu szczegółowy<a title="DO POBRANIA INSTRUKTAŻ UŻYTKOWANIA APARATU ORTODONTYCZNEGO.doc" href="#"> instruktaż użytkowania aparatu.&nbsp;</a></p>
                                    <p style="text-align: left;">Na ostatniej, najbardziej wyczekiwanej wizycie, aparaty są zdejmowane, a zęby czyszczone z kleju.&nbsp;<br />Pacjent otrzymuje <a title="APARTAY ORTO" href="/ortodoncja/apartay-ortodontyczne/">aparat retencyjny</a> (retainer klejony lub szyna retencyjna), którego zadaniem jest utrzymanie wyników leczenia.&nbsp;<br />Aparat stały, który był noszony na zębach, można zabrać na pamiątkę.</p>
                                    <p style="text-align: left;">Po zakończeniu leczenia, raz na sześć miesięcy, należy pojawić się na krótkiej kontroli. Wizyty te są bezpłatne.</p></div>
                                <div style="clear: both;"></div>
                            </div>
                        </div>
                        <div className="clr"></div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default PrzedPo;