import React from "react";
import { Link } from "react-router-dom";

const NoMatch = () => {
    return (
      <div>
        <h2>Nie znaleziono strony</h2>
        <p>
          <Link to="/">Powrót do strony głównej</Link>
        </p>
      </div>
    );
  }

export default NoMatch;