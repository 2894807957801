import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './App.css';


function App() {
  
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
    <>
    <div className="section-wrapper  navbar-fixed-top " id="section-1">
    <div className="container">
      <header>
        <div className="row">
          <div className="grc col-lg-4 col-md-5 col-sm-5 col-xs-11 columns logo">
            <div className="inner-wrapper">
              <div className="content">
                <p><a title="Home" href="/"><img src="/images/Dentistika.png"
                      alt="Dentistika.png" width="286" height="70" /></a></p>
              </div>
              <div style={{clear: "both"}}></div>
            </div>
          </div>
          <div className="grc col-lg-7 col-md-6 col-sm-6 col-xs-12 columns header-kontakt">
            <div className="inner-wrapper">
              <div className="content">
                <p className="top-contact-1"><img src="/images/ikona-telefon.png" alt="ikona-telefon.png" width="34"
                    height="35" />&nbsp;+48 530 031 032</p>
                <p className="top-contact-1"><img src="/images/ikona-mail.png" alt="ikona-mail.png" width="34" height="35"/>
                  <a href="mailto:dentestika@gmail.com">dentestika@gmail.com</a></p>
                <p className="top-contact-1"><img src="/images/ikona-map.png" alt="ikona-map.png" width="34"
                    height="35"/>&nbsp;ul.&nbsp;Kolista&nbsp;3/1,&nbsp;Wrocław&nbsp;-&nbsp;Kozanów
                </p>
              </div>
              <div className="content">
                <p style={{textAlign: "center"}}><strong><span style={{fontSize: "small", color: "#000000"}} >PRZY BRAKU
                      POŁĄCZENIA PROSIMY O
                      WYSŁANIE SMSa - ODDZWONIMY JAK NAJSZYBCIEJ</span></strong></p>
              </div>
              <div style={{clear: "both"}}></div>
            </div>
          </div>
          <div className="grc col-xs-1 columns language">
            <div className="inner-wrapper">
              <a href="http://www.dentestika.pl/"><img className="ccm-image-block" alt="" src="/images/ikona-pl.png"
                  width="20" height="20"/></a><a href="http://www.dentestika.pl/gb/"><img className="ccm-image-block" alt=""
                  src="/images/ikona-gb.png" width="20" height="20"/></a><a href="http://www.dentestika.pl/de/"><img
                  className="ccm-image-block" alt="" src="/images/ikona-de.png" width="20" height="20" /></a>
              <div style={{clear: "both"}}></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="grc col-sm-1 columns home-menu">
            <div id="blockStyle228Main5" className="button-home ccm-block-styles">
              <a href="http://www.dentestika.pl/"><img className="ccm-image-block" alt="" src="/images/ikona-menu.png"
                  width="48" height="48" /></a>
            </div>
          </div>
          <div className="grc col-sm-11 col-xs-12 columns header-menu">
            <div className="inner-wrapper">
              <div id="blockStyle1284Main3" className="menu-align ccm-block-styles">
                <div className="navbar nav-wrapper " role="navigation">
                  <div className="container-fluid">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#navbar-collapse-1284">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-collapse-1284">
                      <ul className="nav nav-header">
                        <li className="first">
                          <Link className="first" to="/cennik" target="_top">Cennik</Link>
                          {/* <ul className="dropdown">
                            <li className="first">
                              <a className="first" href="/oferta/promocje/"
                                target="_self">Promocje</a>
                            </li>
                          </ul> */}
                        </li>
                        <li className="has-submenu">
                          <a className="has-submenu" href="/ortodoncja/"
                            target="_self">Ortodoncja</a>
                          <ul className="dropdown">
                            <li className="first">
                              <a className="first" href="/ortodoncja/przed-i-po/"
                                target="_self">PRZED I PO</a>
                            </li>
                            <li className="">
                              <a className="" href="/ortodoncja/apartay-ortodontyczne/"
                                target="_self">APARTAY ORTODONTYCZNE</a>
                            </li>
                            <li className="">
                              <a className="" href="/ortodoncja/pytania-i-odpowiedzi/"
                                target="_self">PYTANIA I ODPOWIEDZI</a>
                            </li>
                          </ul>
                        </li>
                        <li className="has-submenu">
                          <a className="has-submenu" href="/leczenie-kanalowe/"
                            target="_self">Leczenie kanałowe</a>
                          <ul className="dropdown">
                            <li className="first">
                              <a className="first" href="/leczenie-kanalowe/zalety-mikroskopu/"
                                target="_self">Zalety mikroskopu</a>
                            </li>
                            <li className="">
                              <a className="" href="/leczenie-kanalowe/krok-po-kroku/"
                                target="_self">Krok po kroku</a>
                            </li>
                            <li className="">
                              <a className="" href="/leczenie-kanalowe/leczenie-pytanie/"
                                target="_self">Pytania i odpowiedzi</a>
                            </li>
                          </ul>
                        </li>
                        <li className="has-submenu">
                          <a className="has-submenu" href="/dla-pacjenta/" target="_self">Dla
                            pacjenta</a>
                          <ul className="dropdown">
                            <li className="first">
                              <a className="first" href="/dla-pacjenta/pytania-i-odpowiedzi/"
                                target="_self">Pytania i Odpowiedzi</a>
                            </li>
                            <li className="">
                              <a className="" href="/dla-pacjenta/zalecenia-pozabiegowe/"
                                target="_self">ZALECENIA POZABIEGOWE</a>
                            </li>
                            <li className="">
                              <a className="" href="/dla-pacjenta/do-pobrania/" target="_self">DO
                                POBRANIA</a>
                            </li>
                            <li className="">
                              <a className="" href="/dla-pacjenta/galeria/"
                                target="_self">GALERIA</a>
                            </li>
                            <li className="">
                              <a className="" href="/dla-pacjenta/aktualnosci/"
                                target="_self">AKTUALNOŚCI</a>
                            </li>
                          </ul>
                        </li>
                        <li className="">
                          <Link to="/kontakt" target="_top">Kontakt</Link>
                        </li>
                      </ul>
                      <div className="ccm-spacer"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content" style={{display: "none"}}>
                <p style={{textAlign: "center"}}><span
                style={{fontFamily: "arial black, avant garde", color: "#ff0000", fontSize: "large"}} ><span style={{fontSize: "x-large"}}>
                  Z powodu epidemii oraz w trosce o wspólne dobro
                      pacjentów i personelu <span style={{textDecoration: "underline"}}><strong>GABINET PRACUJE W
                          ZMIENIONYCH GODZINACH</strong></span>. W celu ustalenia terminu
                      wizyty prosimy o kontakt SMSem na numer 530031032. W przypadkach pilnych
                      oraz bólowych - 606608902. Mailowo <a
                        href="mailto:dentestika@gmail.com">dentestika@gmail.com</a></span>
                  </span></p>
              </div>
              <div style={{clear: "both"}}></div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </header>
    </div>
  </div>
  <div className="full section-wrapper" id="section-2">
    <div className="container">
      <section id="slider" className="full row">
        <div className="grc col-md-12 columns banner">
          <div className="inner-wrapper">
            <img className="nivo-main-image" src="/images/baner_g.png"
            style={{display: "inline", height: "auto", maxWidth: "100%"}} />
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        <div className="clr"></div>
      </section>
    </div>
  </div>
  <Outlet />
  
  <div className="section-wrapper" id="section-8">
    <div className="container">
      <section id="footer-contact" className="row">
        <div className="grc col-md-12 columns footer-odwiedz">
          <div className="inner-wrapper">
            <div className="content">
              <h2>ODWIEDŹ NAS</h2>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-4 columns footer-gdzie">
          <div className="inner-wrapper">
            <div className="content">
              <h4><a title="Kontakt" href="/kontakt/">GDZIE JESTEŚMY</a></h4>
            </div>
            <div className="content">
              <p><a title="Kontakt" href="/kontakt/"><img src="/images/mapa.jpg"
                    alt="mapa.jpg" width="202" height="202" /></a>
              </p>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-4 columns footer-otwarcie">
          <div className="inner-wrapper">
            <div className="content">
              <h4>GODZINY OTWARCIA</h4>
            </div>
            <div className="content">
              <h6><strong>Pn.:</strong> 9-14</h6>
              <h6><strong>Wt.:</strong> 9-14</h6>
              <h6><strong>Śr.:</strong> 14-21</h6>
              <h6><strong>Czw.:</strong> 14-20</h6>
              <h6><strong>Pt.:</strong> 9-14</h6>
              <h6><strong>Sob.:</strong> nieczynne</h6>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-4 columns footer-skontaktuj">
          <div className="inner-wrapper">
            <div className="content">
              <h4>SKONTAKTUJ SIĘ Z NAMI&nbsp;</h4>
            </div>
            <div className="content">
              <h6>Stomatologia:</h6>
              <h6><a href="tel:530 031 032"><strong><img src="/images/ikona-telefon2.png" alt="ikona-telefon2.png"
                                                         width="37" height="37"/>&nbsp;
                +48 530 031 032</strong></a></h6>
              <h6>Ortodoncja:</h6>
              <h6><a href="tel:884 608 902"><strong><strong><img src="/images/ikona-telefon2.png" alt="ikona-telefon2.png" width="37"
                                       height="37"/>&nbsp;&nbsp;</strong>+48 884 608 902</strong></a></h6>
              <h6><strong><img src="/images/ikona-mail2.png"
                               alt="ikona-mail2.png" width="37" height="37"/>&nbsp; <a
                  href="mailto:dentestika@gmail.com">dentestika@gmail.com</a></strong></h6>
              <h6><strong><img src="/images/ikona-formularz2.png" alt="ikona-formularz2.png" width="37"
                               height="37"/>&nbsp;&nbsp;<strong><a title="Kontakt"
                                                                   href="/kontakt/">Formularz
                kontaktowy</a></strong></strong></h6>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-12 columns footer-home">
          <div className="inner-wrapper">
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="clr"></div>
      </section>
      
    </div>
  </div>
  
  <div className="section-wrapper" id="section-9">
    <div className="container">
      <footer className="row">
        <div className="grc col-md-2 columns footer-list1">
          <div className="inner-wrapper">
            <div className="content">
              <h4>DENTESTIKA</h4>
              <h6><a title="Home" href="/">Strona główna</a></h6>
              <h6><a title="Kontakt" href="/kontakt/">Kontakt</a></h6>
              <h6><a title="Kontakt" href="/kontakt/">Jak do nas trafić</a>
              </h6>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-2 columns footer-list2">
          <div className="inner-wrapper">
            <div className="content">
              <h4>OFERTA</h4>
              <h6><Link title='Ortodoncja' to="/ortodoncja">Ortodoncja</Link></h6>
              <h6><a title="Leczenie kanałowe" href="/leczenie-kanalowe/">Leczenie
                  kanałowe</a></h6>
              <h6><a title="Oferta" href="/oferta/">Stomatologia ogólna</a>
              </h6>
              <h6><a title="Oferta" href="/oferta/">Chirurgia
                  stomatologiczna</a></h6>
              <h6><a title="Oferta" href="/oferta/">Protetyka</a></h6>
              <h6><a title="Oferta" href="/oferta/">Periodontologia</a></h6>
              <h6><a title="Promocje" href="/oferta/promocje/">Promocje</a>
              </h6>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-3 columns footer-list3">
          <div className="inner-wrapper">
            <div className="content">
              <h4><a title="Ortodoncja" href="/ortodoncja/">ORTODONCJA</a></h4>
              <h6><a title="Ortodoncja" href="/ortodoncja/">Bezpłatna
                  konsultacja</a></h6>
              <h6><a title="Ortodoncja" href="/ortodoncja/">Pierwsza wizyta
                  dziecka</a></h6>
              <h6><a title="PRZED I PO" href="/ortodoncja/przed-i-po/">Przed i
                  po leczeniu</a></h6>
              <h6><a title="Ortodoncja" href="/ortodoncja/">Oferta
                  ortodoncja</a></h6>
              <h6><a title="APARTAY ORTO" href="/ortodoncja/apartay-ortodontyczne/">Aparaty
                  ortodontyczne</a></h6>
              <h6><a title="PYTANIA I ODPOWIEDZI"
                  href="/ortodoncja/pytania-i-odpowiedzi/">Pytania i
                  odpowiedzi</a></h6>
              <h6><a title="DO POBRANIA" href="/dla-pacjenta/do-pobrania/">Do
                  pobrania</a></h6>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-3 columns footer-list4">
          <div className="inner-wrapper">
            <div className="content">
              <h4><a title="Leczenie kanałowe" href="/leczenie-kanalowe/">LECZENIE
                  KANAŁOWE</a></h4>
              <h6><a title="Pytania i odpowiedzi"
                  href="/leczenie-kanalowe/leczenie-pytanie/">Kiedy i
                  dlaczego</a></h6>
              <h6><a title="Zalety mikroskopu"
                  href="/leczenie-kanalowe/zalety-mikroskopu/">Zalety
                  mikroskopu</a></h6>
              <h6><a title="Krok po kroku" href="/leczenie-kanalowe/krok-po-kroku/">Krok po
                  kroku</a></h6>
              <h6><a title="Leczenie kanałowe" href="/leczenie-kanalowe/">Oferta
                  endodoncja</a></h6>
              <h6><a title="Pytania i odpowiedzi"
                  href="/leczenie-kanalowe/leczenie-pytanie/">Pytania i
                  odpowiedzi</a></h6>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        
        <div className="grc col-md-2 columns footer-list5">
          <div className="inner-wrapper">
            <div className="content">
              <h4><a title="Dla pacjenta" href="/dla-pacjenta/">DLA
                  PACJENTA</a></h4>
              <h6><a title="Kontakt" href="/kontakt/">Godziny otwarcia
                  gabinetu</a></h6>
              <h6><a title="Kontakt" href="/kontakt/">Grafik pracy lekarzy</a>
              </h6>
              <h6><a title="Pytania i Odpowiedzi"
                  href="/dla-pacjenta/pytania-i-odpowiedzi/">Pytania i
                  odpowiedzi</a></h6>
              <h6><a title="ZALECENIA POZABIEGOWE"
                  href="/dla-pacjenta/zalecenia-pozabiegowe/">Zalecenia
                  pozabiegowe</a></h6>
              <h6><a title="DO POBRANIA" href="/dla-pacjenta/do-pobrania/">Do
                  pobrania</a></h6>
              <h6><a title="GALERIA" href="/dla-pacjenta/galeria/">Galeria</a>
              </h6>
              <h6><a title="AKTUALNOŚCI" href="/dla-pacjenta/aktualnosci/">Aktualności</a>
              </h6>
            </div>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        <div className="grc col-md-1 columns footer-strzalka">
          <div className="inner-wrapper">
            <a href="#" onClick={topFunction}><img className="ccm-image-block"  alt="" src="/images/ikona-strzalka2.png" width="28"
                height="29" /></a>
            <div style={{clear: "both"}}></div>
          </div>
        </div>
        <div className="clr"></div>
      </footer>
    </div>
  </div>
  </>
  );
}

export default App;
