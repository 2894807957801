import React from "react";

const Ortodoncja = () => {
    return (
        <>
            <div className="section-wrapper" id="section-4a">
                <div className="container">

                    <section id="content4" className="row">

                        <div className="grc col-md-12 columns podstrona3a">

                            <div className="inner-wrapper">
                                <div className="content"><h2>NOWOCZESNA ORTODONCJA</h2>
                                    <h4>Od dawna chciałeś poprawić wygląd swoich zębów? Marzysz o pięknym i zdrowym uśmiechu? Nie każdego natura obdarza idealnym zgryzem, a zęby są najważniejszą ozdobą twarzy. Proste i białe sprawiają, że wyglądamy atrakcyjnie. Są naszą wizytówką i dodają pewności siebie.&nbsp;Ortodoncja jest jedną z najszybciej rozwijających się dziedzin stomatologii i już teraz pozwala nam na zastosowanie aparatów, które są praktycznie całkowicie niewidoczne. Z drugiej strony, jeżeli nosimy na zębach aparat, oznacza to, że dbamy o swoje zdrowie i wygląd. W żadnym wieku nie jest za późno na rozpoczęcie leczenia. W naszym gabinecie oferujemy Państwu nowoczesne leczenie ortodontyczne w miłej, rodzinnej atmosferze.&nbsp;</h4>
                                    <h4>W ofercie znajdziecie Państwo aparaty najprostsze, czyli ruchome ( tzw. wyjmowane ) oraz <a title="APARTAY ORTODONTYCZNE" href="/ortodoncja/aparaty-ortodontyczne/">aparaty stałe</a> &nbsp;- metalowe, estetyczne, złote. Specjalizujemy się również w leczeniu <a title="APARTAY ORTODONTYCZNE" href="/ortodoncja/aparaty-ortodontyczne/">aparatami językowymi</a> oraz systemem <a title="APARTAY ORTODONTYCZNE" href="/ortodoncja/aparaty-ortodontyczne/">Clear Aligner</a>. Jako jeden z niewielu gabinetów stosujemy metody i aparaty pozwalające uniknąć usuwania zębów.</h4>
                                    <h4>Na konsultację ortodontyczną może przyjść każdy, kto chce poprawić swój uśmiech.</h4></div><div className="content"><h2>BEZPŁATNA KONSULTACJA</h2>
                                    <p>&nbsp;</p>
                                    <h4>Na konsultację ortodontyczną dobrze jest przynieść aktualne zdjęcie panoramiczne (nie starsze niż dwa lata), a także ewentualną dokumentację dotychczasowego leczenia.&nbsp;<br />Na tej wizycie diagnozujemy wadę zgryzu, rozmawiamy o Państwa potrzebach i oczekiwanych efektach. Zostanie Państwu również udzielona informacja odnośnie planu, czasu trwania i kosztów leczenia ortodontycznego.</h4></div><div className="content"><h2>PIERWSZA WIZYTA DZIECKA</h2>
                                    <h4>Pierwsza wizyta u ortodonty może odbyć się w 4 lub 5 roku życia. Warto podkreślić, że zęby mleczne nie wymagają zazwyczaj leczenia ortodontycznego. Jeżeli jednak młody pacjent posiada widoczne wady zgryzu już w tym okresie to warto udać się na konsultacje do specjalisty. <strong>Rodziców powinny zaniepokoić:</strong> asymetria w rysach twarzy, szkodliwe nawyki (np. obgryzanie paznokci, ołówków, długopisów, ssanie palca, smoczka), wada wymowy, oddychanie ustami lub stale otwarta buzia, przedwczesna utrata zębów mlecznych (najczęściej na skutek powikłań próchnicy), nadmiernie wysunięta lub cofnięta bródka, mocno rzucające się w oczy nieprawidłowe ustawienie zębów.&nbsp;<br />U większości dzieci pierwsze wizyty ortodontyczne mają charakter tylko konsultacyjny i mają na celu budowanie zaufania między lekarzem a małym pacjentem. Każdy przypadek rozpatrujemy jednak indywidualnie i jeśli zachodzi taka potrzeba rozpoczynamy leczenie już w tak wczesnym wieku.</h4>
                                    <h4>W późniejszym okresie rozwoju dziecka czyli w 6 - 7 roku jego życia zachodzą największe zmiany w uzębieniu. W przypadku wystąpienia wad zgryzu najczęściej stosuje się <a title="APARTAY ORTODONTYCZNE" href="/ortodoncja/aparaty-ortodontyczne/">aparaty ruchome</a> (zdejmowane). Rzadko aparaty stałe.&nbsp;<br />Moment, kiedy dziecku wypadają mleczne ząbki, a na ich miejsce wychodzą zęby stałe to najlepszy czas na konsultację i rozpoczęcie przygody z ortodoncją.</h4></div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </div>
                        <div className="clr"></div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Ortodoncja;