import React from "react";

const Home = () => {
    return (
        <>
        
            <div className="section-wrapper" id="section-3">
                <div className="container">
                    <section id="content1" className="row">
                        <div className="grc col-md-12 columns box1-text">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <h2 style={{ textAlign: "center" }}>O nas</h2>
                                </div>
                                <div className="content">
                                    <h4>Tak jak każdy gabinet stomatologiczny we Wrocławiu, oferujemy kompleksową opiekę nad
                                        Państwa uśmiechem. To co nas wyróżnia, to naprawdę nowoczesne podejście do leczenia.
                                    </h4>
                                    <h4>Nasz ortodonta Natalia Czarnecka, nie zaleci usuwania zdrowych zębów, aby upiększyć
                                        Państwa uśmiech. Może za to zaproponować przeprowadzenie całego leczenia w sposób
                                        niewidoczny, wykorzystując aparat językowy lub nakładki Clear Aligner.</h4>
                                    <h4>Nasz chirurg i endodonta Michał Czarnecki da nową szansę zębom, które inni skazywali na
                                        usunięcie. Przeprowadzi leczenie kanałowe pod mikroskopem według światowych standardów i
                                        zastosuje najlepsze techniki odbudowy protetycznej.</h4>
                                    <h4>Nasz protetyk nie zniszczy zdrowego zęba wiertłem, jeśli będą możliwe do wykonania
                                        licówki porcelanowe bez szlifowania. Może też oszczędzić zęby filarowe, które normalnie
                                        trzeba by oszlifować, wykonując most kompozytowy na włóknie szklanym.</h4>
                                    <h4>Najważniejsze jest dla nas dobro i spokój pacjenta oraz jego poczucie bezpieczeństwa i
                                        świadomość „bycia w dobrych rękach”. To dlatego nie pobieramy opłat za znieczulenie,
                                        wykonanie prześwietlenia zębów czy tymczasowe zaopatrzenie ubytków. Przegląd
                                        stomatologiczny i konsultacja ortodontyczna są bezpłatne, podobnie jak wykonanie planu
                                        leczenia. A każda wizyta odbywa się w rodzinnej atmosferze z uśmiechem na ustach,
                                        zarówno personelu jak i pacjentów.</h4>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                        <div className="grc col-md-12 columns podstrona1">
                            <div className="inner-wrapper">
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                        <div className="clr"></div>
                    </section>
                </div>
            </div>
            <div className="section-wrapper" id="section-5">
                <div className="container">
                    <section id="content3" className="row">
                        <div className="grc col-md-12 columns box10-text">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <h2>NASZ ZESPÓŁ</h2>
                                </div>
                                <div className="content">
                                    <h4>Jesteśmy zgranym zespołem wspólnie pracującym od wielu lat. Mamy ustalony i sprawdzony
                                        podział kompetencji i zakresu obowiązków, tak aby pacjent mógł oczekiwać od każdego z
                                        nas najwyższej jakości świadczonych usług. Nasze doświadczenie i nieustanny rozwój
                                        zawodowy potwierdzają dyplomy ukończenia specjalizacji, legitymacje członkowskie
                                        towarzystw naukowych, certyfikaty z kursów krajowych i zagranicznych oraz publikacje w
                                        czasopismach stomatologicznych.</h4>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                        <div className="grc col-md-6 columns box11">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <p><img src="./images/profil-natalia.png" alt="profil-natalia.png" width="351" height="353" /></p>
                                </div>
                                <div className="content">
                                    <h1>Natalia Czarnecka</h1>
                                </div>
                                <div className="content">
                                    <h2>lekarz stomatolog ortodonta</h2>
                                    <h3>&nbsp;</h3>
                                </div>
                                <div className="content">
                                    <h4>Doświadczony praktyk z wieloletnim stażem. W ortodoncji preferuje metody niewymagające
                                        usuwania zębów. Często stosuje aparaty językowe oraz leczenie niewidocznymi nakładkami
                                        Clear Aligner. Świetna w siatkówkę plażową, zapalona tenisistka, uwielbia narciarstwo i
                                        dobre kryminały.</h4>
                                </div>
                                <div id="HTMLBlock1291" className="HTMLBlock">
                                    <iframe frameBorder="0" scrolling="no" allowtransparency="true" data-id="ne27777777"
                                        src="https://www.znanylekarz.pl/ajax/marketing/doctor/widget/small/natalia-czarnecka/null?customUtm=null&amp;id=ne27777777&amp;header=null&amp;content=null&amp;fullwidth=null&amp;referer=http%3A%2F%2Fwww.dentestika.pl%2Fpl%2F&amp;hide_branding=null&amp;widget_position=bottom&amp;opinion=true"
                                        style={{ border: "none", overflow: "hidden", width: "100%", height: "73px" }}
                                        allow="autoplay 'self'; fullscreen 'self'"></iframe>
                                </div>
                                <div className="content">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                        <div className="grc col-md-6 columns box12">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <p><img src="./images/profil-michal.png" alt="profil-michal.png" width="351" height="353" /></p>
                                </div>
                                <div className="content">
                                    <h1>Michał Czarnecki</h1>
                                </div>
                                <div className="content">
                                    <h2>lekarz stomatolog endodonta<br />&nbsp;</h2>
                                    <h3>specjalista chirurgii stomatologicznej</h3>
                                </div>
                                <div className="content">
                                    <h4>Wykonuje leczenie kanałowe pod mikroskopem oraz zabiegi chirurgiczne w jamie ustnej jak
                                        operacyjne usuwanie zębów zatrzymanych. Zajmuje się także trudnymi przypadkami
                                        endodontycznymi kierowanymi z innych gabinetów. Uwielbia wyzwania stomatologiczne. Jego
                                        pasja to ratowanie zębów przed usunięciem z wykorzystaniem nowoczesnych metod z
                                        pogranicza endodoncji, protetyki i chirurgii. Uwielbia podróże oraz sport -
                                        &nbsp;siatkówkę plażową, tenis i narty.&nbsp;</h4>
                                </div>
                                <div id="HTMLBlock1291" className="HTMLBlock">
                                    <iframe frameBorder="0" scrolling="no" allowtransparency="true" data-id="uqallllllm"
                                        src="https://www.znanylekarz.pl/ajax/marketing/doctor/widget/small/michal-czarnecki/null?customUtm=null&amp;id=uqallllllm&amp;header=null&amp;content=null&amp;fullwidth=null&amp;referer=http%3A%2F%2Fwww.dentestika.pl%2Fpl%2F&amp;hide_branding=null&amp;widget_position=bottom&amp;opinion=true"
                                        style={{ border: "none", overflow: "hidden", width: "100%", height: "73px" }}
                                        allow="autoplay 'self'; fullscreen 'self'"></iframe>
                                </div>
                                <div className="content">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-md-6 columns box13">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <p><img src="./images/profil-monika.png" alt="profil-monika.png" width="351" height="353" /></p>
                                </div>
                                <div className="content">
                                    <h1>Monika Świerzbińska</h1>
                                </div>
                                <div className="content">
                                    <h2>higienistka stomatologiczna</h2>
                                    <h3>&nbsp;</h3>
                                </div>
                                <div className="content">
                                    <h4>Od ponad ośmiu lat tworzy zgrany zespół z doktorem Czarneckim. Samodzielnie wykonuje
                                        zabiegi usuwania kamienia nazębnego, profesjonalnego oczyszczania zębów i fluoryzacji.
                                        To ona odpowiada za kontak z pacjentami, rejestrację telefoniczną oraz potwierdzanie
                                        wizyt. Nieoceniona i oddana dla naszego gabinetu.</h4>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-md-6 columns box14">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <p><img src="./images/profil-nati.png" alt="profil-agata.png" width="351" height="353" /></p>
                                </div>
                                <div className="content">
                                    <h1>Natalia Semeniaka</h1>
                                </div>
                                <div className="content">
                                    <h2>asystentka stomatologiczna</h2>
                                    <h3>&nbsp;</h3>
                                </div>
                                <div className="content">
                                    <h4>Natalia z precyzją, profesjonalizmem i zawsze pozytywnym nastawieniem asystuje w leczeniu ortodontycznym. Z łatwością nawiązuje kontakty i zawsze sumiennie wykonuje swoje zadania. Zaraża uśmiechem i z radością obserwuję, jak pacjenci odwzajemniaja swój (piękniejący z wizyty na wizytę) uśmiech.</h4>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="clr"></div>
                    </section>

                </div>
            </div>

            <div className="section-wrapper" id="section-6">
                <div className="container">
                    <section id="content4" className="row">
                        <div className="grc col-md-12 columns box15-text">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <h2>NASZE GABINETY</h2>
                                </div>
                                <div className="content">
                                    <h4>W gabinecie fioletowym poprawiamy samopoczucie, podwyższamy samoocenę oraz dajemy nowe
                                        powody do radości. Jednym słowem upiększamy uśmiechy naszych pacjentów. To tu następują
                                        ortodontyczne metamorfozy.</h4>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col1">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-parking.png" width="66" height="66" /></a>
                                <div className="content">
                                    <h3>PARKING</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col2">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-wifi.png" width="66" height="66" /></a>
                                <div className="content">
                                    <h3>WIFI</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col3">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-klimatyzacja.png" width="66"
                                    height="66" /></a>
                                <div className="content">
                                    <h3>KLIMATYZACJA</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col4">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-przyjazny.png" width="66"
                                    height="66" /></a>
                                <div className="content">
                                    <h3>GABINET PRZYJAZNY DZIECIOM</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-md-12 columns podstrona3">
                            <div className="inner-wrapper">
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="clr"></div>
                    </section>

                </div>
            </div>

            <div className="section-wrapper" id="section-7">
                <div className="container">
                    <section id="content5" className="row">
                        <div className="grc col-md-12 columns box20-text">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <h4>Gabinet czerwony przygotowany jest do leczenia stomatologiczno - protetycznego, a w
                                        szczególności do ratowania zębów poprzez leczenie kanałowe pod mikroskopem. Tutaj
                                        znajdują się mikroskop stomatologiczny, nowoczesne urządzenia do opracowania i
                                        wypełniania kanałów oraz cyfrowy rentgen.</h4>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col5">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-rtg.png"
                                    width="66" height="66" /></a>
                                <div className="content">
                                    <h3>RTG</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col6">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-mikroskop.png"
                                    width="66" height="66" /></a>
                                <div className="content">
                                    <h3>MIKROSKOP</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col7">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-inwalidzka.png"
                                    width="66" height="66" /></a>
                                <div className="content">
                                    <h3>DOSTOSOWANY DLA OSÓB NIEPEŁNOSPRAWNYCH</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-3 columns col8">
                            <div className="inner-wrapper">
                                <a href="/"><img className="ccm-image-block" alt="" src="./images/ikona-zab.png"
                                    width="66" height="66" /></a>
                                <div className="content">
                                    <h3>POMOC PRZY BÓLU ZĘBA</h3>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>

                        <div className="grc col-xs-12 columns podstrona4">
                            <div className="inner-wrapper">
                                <div className="content">
                                    <h6><a title="GALERIA" href="/dla-pacjenta/galeria/">Zobacz
                                        więcej</a></h6>
                                </div>
                                <div className="clr"></div>
                            </div>
                        </div>

                        <div className="clr"></div>
                    </section>

                </div>
            </div>
        </>
    )
}

export default Home;